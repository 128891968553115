@font-face {
  font-family: 'Source Serif';
  src: url(./SourceSerif4-Semibold.otf);
}

.card-print {
  border-color: #aaa;
  margin-bottom: 1em;
  margin-top: 2em;
  padding: 1em;
}

#print-area {
  display: none;
}

.details {
  display: flex;
  align-items: center;
}

.qrcode {
  max-width: 175px;
  padding: 1em;
}

.hidden {
  display: none;
}
textarea {
  background-color: #fff;
  border: solid 1px #ddd;
  font-family: 'Source Serif', serif !important;
  font-size: 1.2em !important;
  margin-bottom: 0;
  height: 40px !important;
  min-height: 0px !important;
  overflow: hidden;
  resize: none;
}
textarea#password {
  height: 60px !important;
}

hr {
  margin-top: 0;
}

button {
  height: 50px;
  width: 180px;
}

#settings {
  margin-bottom: 1em;
  padding: 1em;
}

#settings label span {
  margin-right: 8px;
}

@media print {
  body * {
    visibility: hidden;
  }
  #print-area,
  #print-area * {
    visibility: visible;
  }
  #print-area {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 0em;
  }
  .card-print {
    border-style: dashed;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
